




import { defineComponent, PropType, ref, watch } from '@vue/composition-api';
import * as echarts from 'echarts';
import { EChartsOption } from 'echarts';

export default defineComponent({
  name: 'VEcharts',
  props: {
    option: {
      type: Object as PropType<EChartsOption>,
      required: true,
    },
  },
  setup(props) {
    const canvas = ref();

    watch(canvas, (element, oldElement, onInvalidate) => {
      const myChart = echarts.init(element);

      const onResize = () => {
        myChart.resize();
      };

      window.addEventListener('resize', onResize);

      myChart.setOption(props.option);

      onInvalidate(() => {
        window.removeEventListener('resize', onResize);
        myChart.dispose();
      });
    });

    return {
      canvas,
    };
  },
});
