




















import { defineComponent, PropType, ref, watch } from '@vue/composition-api';

export default defineComponent({
  name: 'PlotPreloader',
  props: {
    error: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
  },
  setup() {
    const progress = ref<HTMLDivElement>();

    watch(progress, (element, oldElement, onInvalidate) => {
      let time = 0;
      let val = 0;
      let speed = 0.1;

      const requestId = window.requestAnimationFrame(animate);

      function animate(timestamp: number) {
        if (progress.value) {
          progress.value.style.transform = `scaleX(${val})`;
        }

        if (timestamp - time > 500 && val + speed <= 1) {
          val += speed;
          time = timestamp;
          speed = speed * 0.91;
        }
        window.requestAnimationFrame(animate);
      }

      onInvalidate(() => {
        window.cancelAnimationFrame(requestId);
      });
    });

    return {
      progress,
    };
  },
});
