






import { defineComponent } from '@vue/composition-api';

import usePopoverContainer from './usePopoverContainer';

export default defineComponent({
  name: 'PopoverContainer',
  setup() {
    const { model, syncVisibility } = usePopoverContainer();
    return {
      model,
      syncVisibility,
    };
  },
});
