import { computed } from '@vue/composition-api';

import useSettingsWidget from '@/pages/StressMapPage/components/SettingsWidget/useSettingsWidget';

export default function useToggleSettingsButton() {
  const { model, toggle } = useSettingsWidget();
  return {
    model: computed(() => ({
      isSettingsOpen: model.value.isOpen,
    })),
    toggle,
  };
}
