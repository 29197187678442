import { computed, ref } from '@vue/composition-api';

const state = ref({
  isOpen: false,
  justCopied: false,
});

export default function usePopoverContainer() {
  const syncVisibility = (value: boolean) => {
    state.value.isOpen = value;
  };

  return {
    model: computed(() => ({
      ...state.value,
    })),
    syncVisibility,
  };
}
