import { computed } from '@vue/composition-api';

import useBooleanModel from '@/utils/useBooleanModel';
import useSettingsWidget from '@/pages/StressMapPage/components/SettingsWidget/useSettingsWidget';
import injectStrict from '@/utils/injectStrict';
import { StoreKey } from '@/store';
import useSelectedFile from '@/store/projections/useSelectedFile';

export type InfoContent = { title: string; long: number; lat: number; val: number };

const { state: booleanState, toggle, open, close } = useBooleanModel(false);

export default function useTheInfoArea() {
  const store = injectStrict(StoreKey);
  const { model } = useSettingsWidget();
  const { model: selectedFileModel } = useSelectedFile();

  return {
    model: computed(() => {
      const content = store.model.area.data
        ? {
            ...store.model.area.data,
            long: store.model.area.data.long.toFixed(3),
            lat: store.model.area.data.lat.toFixed(3),
            title: `Area ${store.model.area.data.index}`,
            val: store.model.area.data.val / (selectedFileModel.value.file?.scaling.factor || 1),
          }
        : {
            title: '',
            long: 0,
            lat: 0,
            val: 0,
          };

      return {
        content,
        isOpen: booleanState.value,
        isOffsetted: model.value.isOpen,
        cellSize: store.model.geotiff.cellSize,
        selectedFile: selectedFileModel.value,
      };
    }),
    toggle,
    open,
    close,
  };
}
