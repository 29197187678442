import { computed } from '@vue/composition-api';

import injectStrict from '@/utils/injectStrict';
import { StoreKey } from '@/store';
import useSelectedFile from '@/store/projections/useSelectedFile';
import { createHistoryKey } from '@/store/domain/area';

export default function useAttributeHistory() {
  const store = injectStrict(StoreKey);
  const { model: selectedFileModel } = useSelectedFile();

  return {
    model: computed(() => {
      const fileHistoryUrl = selectedFileModel.value.file?.historyURL;

      if (!store.model.area.data || !fileHistoryUrl) {
        return null;
      }

      const { long, lat } = store.model.area.data;
      const longLatKey = createHistoryKey(long, lat, fileHistoryUrl);
      const data = store.model.area.history[longLatKey] || null;

      return {
        data,
      };
    }),
  };
}
