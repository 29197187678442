import { computed } from '@vue/composition-api';

import injectStrict from '@/utils/injectStrict';
import { StoreKey } from '@/store';
import useSelectedFile from '@/store/projections/useSelectedFile';

export default function usePieces() {
  const store = injectStrict(StoreKey);
  const { model: selectedFileModel } = useSelectedFile();

  return {
    model: computed(() => {
      const { colors } = store.model.chart.piecewise.palette.all.find(
        (el) => el.hash === store.model.chart.piecewise.palette.current.hash,
      )!;
      const paletteColors = selectedFileModel.value?.file?.palette?.inverted ? [...colors].reverse() : colors;

      if (!store.model.chart.piecewise.validated.filter.range) {
        return undefined;
      }
      const [min, max] = store.model.chart.piecewise.validated.filter.range;

      const n = store.model.chart.piecewise.validated.count.current;
      const delta = (max - min) / n;

      return Array.from({ length: n }).map((_, index) => ({
        min: Math.round(min + index * delta),
        max: Math.round(min + (index + 1) * delta),
        color: paletteColors[index],
      }));
    }),
  };
}
