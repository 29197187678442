import { computed, onMounted, ref } from '@vue/composition-api';
import { interval, Subject } from 'rxjs';
import { debounce, tap } from 'rxjs/operators';

import useShareSettings from '@/store/ui/useShareSettings';

const state = ref<{
  isOpen: boolean;
  justCopied: string | null;
  timer: any;
}>({
  isOpen: false,
  justCopied: null,
  timer: null,
});

export default function useShareMapView() {
  const copy$ = new Subject<string>();

  const { model: shareSettingsModel } = useShareSettings();

  onMounted(() => {
    const sub$ = copy$
      .pipe(
        tap((val) => {
          state.value.justCopied = val;
        }),
        debounce(() => interval(3000)),
        tap(() => {
          state.value.justCopied = null;
        }),
      )
      .subscribe();

    return () => {
      sub$.unsubscribe();
    };
  });

  const onCopy = () => {
    // Generating a uniq key to make text blink on repeated clicks
    copy$.next(`${Math.random()}`);
  };

  return {
    model: computed(() => ({
      isSettingsOpen: state.value.isOpen,
      url: shareSettingsModel.value.url,
      justCopied: state.value.justCopied,
    })),
    onCopy,
  };
}
