















































import { defineComponent } from '@vue/composition-api';

import PopoverContainer from '@/components/PopoverContainer';
import Clipboardjs from '@/components/Clipboardjs';
import ToggleShareButton from '@/pages/StressMapPage/components/ToggleShareButton';

import useShareMapView from './useShareMapView';

export default defineComponent({
  name: 'ShareMapView',
  components: {
    ToggleShareButton,
    PopoverContainer,
    Clipboardjs,
  },
  setup() {
    const { model, onCopy } = useShareMapView();
    return {
      model,
      onCopy,
    };
  },
});
