





















import { defineComponent } from '@vue/composition-api';
import VueSlider from 'vue-slider-component';

import { StoreKey } from '@/store';
import injectStrict from '@/utils/injectStrict';

export default defineComponent({
  name: 'OpacityPicker',
  components: {
    VueSlider,
  },
  setup() {
    const store = injectStrict(StoreKey);

    const toolTipFormatter = (val: number) => val / 100;

    return {
      opacity: store.model.chart.opacity,
      updateOpacity: store.actions.chart.updateOpacity,
      toolTipFormatter,
    };
  },
});
