





































































import { defineComponent, PropType } from '@vue/composition-api';
import { XIcon } from 'vue-feather-icons';

import { StoreKey } from '@/store';
import HistoryPlot from '@/pages/StressMapPage/components/HistoryPlot';
import injectStrict from '@/utils/injectStrict';
import BaseSpinner from '@/components/BaseSpinner';

import useTheInfoArea from './useTheInfoArea';

export default defineComponent({
  name: 'TheInfoArea',
  props: {
    parentContainerId: {
      type: String as PropType<string>,
      required: false,
      default: null,
    },
  },
  components: {
    XIcon,
    HistoryPlot,
    BaseSpinner,
  },
  setup(props) {
    const { model, close } = useTheInfoArea();
    const store = injectStrict(StoreKey);

    const getScrollConfig = () => {
      return (() => {
        const defaultConf = { container: window, offset: window.scrollY, delay: 0 };
        if (!props.parentContainerId) {
          return defaultConf;
        }
        const mapWrapper = document.getElementById(props.parentContainerId) as HTMLDivElement | null;
        if (mapWrapper) {
          return { container: mapWrapper, offset: mapWrapper.scrollTop, delay: 200 };
        }
        return defaultConf;
      })();
    };

    const closeAndScrollTop = () => {
      const { container, offset, delay } = getScrollConfig();
      if (offset === 0) {
        close();
      } else {
        container.scrollTo({ top: 0, behavior: 'smooth' });
        setTimeout(close, delay);
      }
    };

    const afterEnter = () => {
      const { container } = getScrollConfig();
      // Just a magic number to show the scroll available. Users are not getting it. Change design?
      container.scrollTo({ top: 220, behavior: 'smooth' });
    };

    return {
      model,
      close: closeAndScrollTop,
      overviewLoader: store.model.overviewLoader,
      afterEnter,
    };
  },
});
