






import { defineComponent } from '@vue/composition-api';
import usePieces from '@/store/projections/usePieces';

export default defineComponent({
  name: 'PaletteRainbow',

  setup() {
    const { model: piecesModel } = usePieces();
    return { piecesModel: piecesModel || [] };
  },
});
