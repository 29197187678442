






import { defineComponent, ref, watch, PropType } from '@vue/composition-api';

import ClipboardJS from 'clipboard';

export default defineComponent({
  name: 'Clipboardjs',
  props: {
    tag: {
      type: String as PropType<string>,
      required: false,
      default: 'button',
    },
    text: {
      type: String as PropType<string>,
      required: false,
      default: 'default text here',
    },
  },
  setup(_, { emit }) {
    const buttonElement = ref();

    const clipboard = ref<ClipboardJS | null>(null);

    watch(buttonElement, (element, oldElement, onInvalidate) => {
      onInvalidate(() => {
        clipboard.value?.destroy();
      });
      clipboard.value = new ClipboardJS(element);

      clipboard.value.on('success', (e) => {
        if (e.action === 'copy') {
          emit('success');
        }
        e.clearSelection();
      });

      clipboard.value.on('error', (e) => {
        emit('error', e);
      });
    });
    return {
      buttonElement,
    };
  },
});
