


















import { defineComponent } from '@vue/composition-api';

import injectStrict from '@/utils/injectStrict';
import { StoreKey } from '@/store';

export default defineComponent({
  name: 'MapLayerPicker',
  setup() {
    const store = injectStrict(StoreKey);

    return {
      model: store.model.overview.mapLayers,
      selectItem: store.actions.overview.selectMapLayer,
    };
  },
});
