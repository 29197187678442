export default class GridBuilder {
  static pointGrid(window: [number, number, number, number], cellSide = 5) {
    // Learn more: https://en.wikipedia.org/wiki/Decimal_degrees, http://edwilliams.org/avform147.htm
    const valueNSorEWatEquator = 111;

    const minLat = window[1];
    const maxLat = window[3];
    const minLong = window[0];
    const maxLong = window[2];
    const longRef = 0;
    const latRef = 0;

    const latDistance = cellSide / valueNSorEWatEquator;
    const longDistance = cellSide / valueNSorEWatEquator; // only close to real when lat ~0. But for visual estimation this should be enough

    const nLatOffset = Math.floor((minLat - latRef) / latDistance);
    const nLongOffset = Math.floor((minLong - longRef) / longDistance);

    const initialLong = nLongOffset * longDistance;
    const initialLat = nLatOffset * latDistance;

    let currentLat = initialLat;
    let currentLong = initialLong;

    const grid = [];

    while (currentLat < maxLat) {
      const row = [];
      while (currentLong < maxLong) {
        const center = [currentLat + latDistance / 2, currentLong + longDistance / 2];
        row.push(center);
        currentLong += longDistance;
      }

      grid.unshift(row);
      currentLat += latDistance;
      currentLong = initialLong;
    }

    return { grid: grid.flat(), latDistance, longDistance, height: grid.length, width: grid[0].length };
  }
}
