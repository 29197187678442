




























































import { computed, defineComponent, onMounted, PropType, ref, watch } from '@vue/composition-api';
import { combineLatest, interval, of, Subject } from 'rxjs';
import { debounce, distinctUntilChanged, map, switchMap, tap } from 'rxjs/operators';

import { getCenter } from 'ol/extent';

// API
import COGExtractor from '@/api/COGExtractor';
// Map/Echarts
import useTheEchartsLayerObserver from '@/pages/StressMapPage/useTheEchartsLayerObserver';
// Components
import TheMosaicLoader from '@/components/TheMosaicLoader';
import TheInfoArea from '@/pages/StressMapPage/components/TheInfoArea';
import SettingsWidget from '@/pages/StressMapPage/components/SettingsWidget';
import ToggleSettingsButton from '@/pages/StressMapPage/components/ToggleSettingsButton';
import RecentDataSources from '@/pages/StressMapPage/components/RecentDataSources';
import ZoomPicker from '@/pages/StressMapPage/components/ZoomPicker';
import ShareMapView from '@/pages/StressMapPage/components/ShareMapView';
import HumbleLink from '@/pages/StressMapPage/components/HumbleLink';
import ZoomToSeeBanner from '@/pages/StressMapPage/components/ZoomToSeeBanner';
// Store
import { StoreKey } from '@/store';
import injectStrict from '@/utils/injectStrict';
import useTheInfoArea from '@/pages/StressMapPage/components/TheInfoArea/useTheInfoArea';
import useTheModal from '@/pages/StressMapPage/components/TheModal/useTheModal';
import useChart from '@/store/projections/useChart';
import useLayout from '@/store/ui/useLayout';
import useShareSettings from '@/store/ui/useShareSettings';
import useSelectedFile from '@/store/projections/useSelectedFile';
import { DEFAULT_CELL_SIZE_KM, MIN_ZOOM_TO_SHOW_CELLS } from '@/constants';

export default defineComponent({
  name: 'StressMapPage',
  props: {
    parentContainerId: {
      type: String as PropType<string>,
      required: false,
      default: null,
    },
  },
  components: {
    TheInfoArea,
    SettingsWidget,
    TheMosaicLoader,
    ToggleSettingsButton,
    RecentDataSources,
    ZoomPicker,
    ShareMapView,
    HumbleLink,
    ZoomToSeeBanner,
  },
  setup() {
    const store = injectStrict(StoreKey);
    // UI
    const { model: layoutModel } = useLayout();
    const { open: openInfo, close: closeInfo } = useTheInfoArea();
    const { open: openModal } = useTheModal();

    const { model: chartModel } = useChart();

    const { model: shareSettingsModel } = useShareSettings();

    const { model: selectedFileModel } = useSelectedFile();

    // Map
    const mapContainerElement = ref<HTMLDivElement>();

    const mapLayerUrl = computed(() => {
      return store.model.overview.mapLayers.all.find((el) => el.id === store.model.overview.mapLayers.selected)!.url;
    });

    const onChartClick = (params: {
      dataIndex: number;
      // long, lat, value
      value: [number, number, number];
    }) => {
      const long = params.value[0];
      const lat = params.value[1];
      store.actions.area.update({
        index: params.dataIndex,
        long,
        lat,
        val: params.value[2],
      });
      openInfo();
    };
    const {
      init: initTheEchartsLayer,
      moveend$,
      clearAll,
      draw: drawEchartsLayer,
      clearSelection,
      redrawFromCache,
      onZoom,
    } = useTheEchartsLayerObserver(mapContainerElement, onChartClick, mapLayerUrl);

    watch(
      () => layoutModel.value.isAreaOpen,
      (isAreaOpen) => {
        if (!isAreaOpen) {
          clearSelection();
        }
      },
    );
    // COG extractor
    const cogExtractor = new COGExtractor();

    // Init
    onMounted(() => {
      initTheEchartsLayer(JSON.parse(JSON.stringify(store.init.share)));
    });

    // Update url
    watch(shareSettingsModel, ({ url }) => {
      store.services.share.sync(url);
    });

    // Get area history data
    watch(
      () => [selectedFileModel.value.file, store.model.area.data] as const,
      ([nextFile, nextAreaData]) => {
        // Need to load history in two cases:
        // 1. Area updated (selected cell changed)
        // 2. File changed saving the currently active cell
        if (nextFile?.url && nextAreaData?.long && nextAreaData?.lat) {
          store.actions.area.updateHistory(
            nextAreaData.long,
            nextAreaData.lat,
            selectedFileModel.value.file?.historyURL,
          );
        }
      },
    );
    // UI streams
    // Data url, requires new file (reinit)
    const dataSource$ = new Subject<{ url: string; scalingFactor: number }>();

    watch(selectedFileModel, (nextSelectedFileModel) => {
      if (nextSelectedFileModel.file) {
        // We add every used file to recent files in order to have fast access to them later
        store.actions.sources.addRecent(nextSelectedFileModel.file.id);
        // Notify that the current file has changed
        dataSource$.next({
          url: nextSelectedFileModel.file.url,
          scalingFactor: nextSelectedFileModel.file.scaling.applied ? 1 : nextSelectedFileModel.file.scaling.factor,
        });
      }
    });

    // Chart settings change, just redraw chart
    const chartSettings$ = new Subject<typeof chartModel.value>();
    watch(chartModel, (nextChart) => {
      if (store.model.geotiff.cellSize) {
        chartSettings$.next(nextChart);
      }
    });

    watch(mapContainerElement, (target, _, onInvalidate) => {
      if (!target) return;

      const chart$ = chartSettings$.pipe(debounce(() => interval(400)));
      const dataSourceN$ = dataSource$.pipe(distinctUntilChanged((prev, curr) => prev.url === curr.url));

      const zoomOrPan$ = moveend$.pipe(
        debounce(() => interval(400)),
        map((event) => {
          const extent = event.map.getView().calculateExtent(event.map.getSize());
          const zoom = event.map.getView().getZoom() as number;
          const center = getCenter(extent) as [number, number];

          return { extent, zoom, center };
        }),
        tap(({ zoom, center }) => {
          store.actions.overview.update({
            zoom: Math.floor(zoom * 10),
            center: { long: center[0], lat: center[1] },
          });
          closeInfo();
        }),
        map((res) => {
          const cellSide = res.zoom > MIN_ZOOM_TO_SHOW_CELLS / 10 ? DEFAULT_CELL_SIZE_KM : null;
          return { ...res, cellSide };
        }),
      );
      const cog$ = combineLatest([dataSourceN$, zoomOrPan$]).pipe(
        tap(([, { cellSide }]) => {
          store.actions.overviewLoader.open();
          store.actions.geotiff.updateCellSize(cellSide);
          if (!cellSide) {
            setTimeout(() => {
              clearAll();
            }, 10);
          }
        }),
        switchMap(([{ url, scalingFactor }, { extent, cellSide }]) => {
          if (!cellSide) {
            return of('prohibited' as const);
          }
          return cogExtractor.read(
            {
              long1: extent[0],
              lat1: extent[1],
              long2: extent[2],
              lat2: extent[3],
            },
            url,
            scalingFactor,
            cellSide,
          );
        }),
        tap((res) => {
          if (res === 'prohibited') {
            store.actions.overviewLoader.close();
          } else if (res) {
            store.actions.geotiff.updateValuesRange([res.minValue, res.maxValue]);
          } else {
            openModal();
            store.actions.overviewLoader.close();
          }
        }),
      );

      const draw$ = combineLatest([chart$, cog$])
        .pipe(
          distinctUntilChanged((prev, curr) => {
            return prev[0].dataHash === curr[0].dataHash;
          }),
          switchMap(([nextChart, nextCOG]) => {
            if (nextCOG === 'prohibited' || nextCOG === null || !nextChart?.ranges) {
              return of(null);
            } else {
              return of({
                layer: drawEchartsLayer({
                  data: nextCOG.data,
                  cellSizeX: nextCOG.cellSizeX,
                  cellSizeY: nextCOG.cellSizeY,
                  opacity: nextChart.opacity,
                  ranges: [...nextChart.ranges],
                }),
                nextCOG,
              });
            }
          }),
          tap((res) => {
            if (res?.nextCOG) {
              if (store.model.area.data) {
                const { long: areaCenterLong, lat: areaCenterLat } = store.model.area.data;

                const equal = (a: number, b: number) => a.toFixed(4) === b.toFixed(4);

                const targetCellIndex = res.nextCOG.data.findIndex(
                  (el) => equal(el.long, areaCenterLong) && equal(el.lat, areaCenterLat),
                );
                if (targetCellIndex > -1) {
                  const targetCell = res.nextCOG.data[targetCellIndex];
                  store.actions.area.updatePart({ val: targetCell.val, index: targetCellIndex });
                  // TODO: research how to prevent echarts bug
                  try {
                    redrawFromCache(targetCellIndex);
                    // eslint-disable-next-line no-empty
                  } catch {}
                }
              }
            }
            store.actions.overviewLoader.close();
          }),
        )
        .subscribe();

      onInvalidate(() => {
        draw$.unsubscribe();
      });
    });

    return {
      mapContainerElement,
      layoutModel,
      overviewLoader: store.model.overviewLoader,
      onZoom,
      mapViewModel: store.model.overview,
      geotiffModel: store.model.geotiff,
      isEmbedded: store.init.isEmbedded,
    };
  },
});
