








import { defineComponent, PropType } from '@vue/composition-api';

import useModelWrapper from '@/utils/modelWrapper';

export default defineComponent({
  name: 'PrimaryInput',
  props: {
    value: {
      type: [String, Number] as PropType<string | number>,
      required: false,
      default: () => '',
    },
  },
  setup(props, { emit }) {
    return {
      localValue: useModelWrapper(props, emit),
    };
  },
});
