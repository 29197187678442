



















import { defineComponent } from '@vue/composition-api';
import StressMapPage from '@/pages/StressMapPage';
import LinkWithParams from '@/components/LinkWithParams';
import { MAP_URL } from '@/constants';

export default defineComponent({
  name: 'StressMapWrapper',
  components: {
    StressMapPage,
    LinkWithParams,
  },
  setup() {
    const mapContainerId = 'stressmap-wrapper';

    return {
      mapContainerId,
      mapUrl: MAP_URL,
    };
  },
});
