
























import { computed, defineComponent } from '@vue/composition-api';
import injectStrict from '@/utils/injectStrict';
import { StoreKey } from '@/store';
import useLayout from '@/store/ui/useLayout';

// import useRecentDataSources from './useRecentDataSources';

export default defineComponent({
  name: 'RecentDataSources',
  components: {},
  setup() {
    // const { model, selectItem } = useRecentDataSources();
    const { model: layoutModel } = useLayout();
    const store = injectStrict(StoreKey);
    return {
      model: computed(() => {
        const options = store.model.sources.recent.map((id) => store.model.sources.all.find((el) => el.id === id));
        return {
          options,
          selected: store.model.sources.selected,
          isVisible: !layoutModel.value.isSettingsWidgetOpen && options.length > 1,
        };
      }),
      selectItem: store.actions.sources.select,
    };
  },
});
