import { computed } from '@vue/composition-api';

import useSettingsWidget from '@/pages/StressMapPage/components/SettingsWidget/useSettingsWidget';
import useTheInfoArea from '@/pages/StressMapPage/components/TheInfoArea/useTheInfoArea';

export default function useLayout() {
  const { model: settingsWidgetModel } = useSettingsWidget();
  const { model: areaModel } = useTheInfoArea();

  return {
    model: computed(() => ({
      isSettingsWidgetOpen: settingsWidgetModel.value.isOpen,
      isAreaOpen: areaModel.value.isOpen,
    })),
  };
}
