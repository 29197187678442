import { computed } from '@vue/composition-api';

import injectStrict from '@/utils/injectStrict';
import { StoreKey } from '@/store';

export default function useSelectedFile() {
  const store = injectStrict(StoreKey);

  return {
    model: computed(() => {
      const file = store.model.sources.all.find((el) => el.id === store.model.sources.selected[0]);
      if (!file) {
        return {
          file: undefined,
        };
      }
      const scaling = file.scaling || {
        factor: 1,
        applied: false,
      };
      return {
        file: {
          ...file,
          scaling,
          units: file.units || '',
        },
      };
    }),
  };
}
