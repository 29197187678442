


















import { defineComponent } from '@vue/composition-api';
import { SettingsIcon, FilterIcon } from 'vue-feather-icons';

import useToggleSettingsButton from './useToggleSettingsButton';

export default defineComponent({
  name: 'ToggleSettingsButton',
  components: {
    SettingsIcon,
    FilterIcon,
  },
  setup() {
    const { model, toggle } = useToggleSettingsButton();
    return {
      model,
      toggle,
    };
  },
});
