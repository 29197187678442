




import { defineComponent, PropType } from '@vue/composition-api';

export default defineComponent({
  name: 'LinkWithParams',
  props: {
    href: {
      type: String as PropType<string>,
      required: false,
      default: '/',
    },
  },
  setup(props) {
    const onClick = (e: MouseEvent) => {
      e.preventDefault();
      window.location.href = `${props.href}${window.location.search}`;
    };
    return {
      onClick,
    };
  },
});
