import { ref } from '@vue/composition-api';

export default function useBooleanModel(initialValue = false) {
  const state = ref(initialValue);

  const open = () => {
    state.value = true;
  };

  const close = () => {
    state.value = false;
  };

  const toggle = () => {
    state.value = !state.value;
  };

  return {
    state,
    open,
    close,
    toggle,
  };
}
