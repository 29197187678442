












import { defineComponent } from '@vue/composition-api';
import { ShareIcon } from 'vue-feather-icons';

export default defineComponent({
  name: 'ToggleShareButton',
  components: {
    ShareIcon,
  },
  props: {
    active: {
      type: Boolean,
      required: true,
    },
  },
});
