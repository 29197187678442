
























import { computed, defineComponent } from '@vue/composition-api';
import { format, parseISO, isFuture } from 'date-fns';

import VEcharts from '@/components/VEcharts';
import PlotPreloader from '@/pages/StressMapPage/components/PlotPreloader';

import useAttributeHistory from '@/store/projections/useAttributeHistory';
import useSelectedFile from '@/store/projections/useSelectedFile';
import { AreaHistoryData } from '@/store/domain/area';

export default defineComponent({
  name: 'HistoryPlot',
  components: {
    VEcharts,
    PlotPreloader,
  },
  setup() {
    const { model: attributeHistoryModel } = useAttributeHistory();
    const { model: selectedFileModel } = useSelectedFile();

    const option = computed(() => {
      if (!attributeHistoryModel.value?.data) {
        return 0;
      }
      const { values, dates, label } = attributeHistoryModel.value.data as AreaHistoryData;
      const xAxisData = dates.map((el) => format(parseISO(el), 'd LLL'));
      const seriesData: (number | null)[] = values.map((el) => (el === null ? null : +el.toFixed(3)));

      const firstFutureDateIndex = dates.findIndex((date) => isFuture(parseISO(date))) - 2;

      return {
        tooltip: {
          trigger: 'axis',
        },
        dataZoom: [
          {
            type: 'inside',
            throttle: 50,
          },
        ],
        grid: {
          left: 36,
          top: 10,
          right: 5,
          bottom: 30,
        },
        legend: {
          data: [label],
        },
        xAxis: {
          type: 'category',
          data: xAxisData,
        },
        yAxis: {
          type: 'value',
        },
        series: [
          {
            name: 'Soil moisture',
            data: seriesData,
            type: 'line',
            smooth: true,
            showSymbol: false,
          },
        ],
        visualMap: {
          show: false,
          dimension: 0,
          pieces: [
            {
              gt: 0,
              lte: firstFutureDateIndex,
              color: '#38424A',
            },
            {
              gt: firstFutureDateIndex,
              color: '#CA5229',
            },
          ],
        },
      };
    });

    return {
      option,
      attributeHistoryModel,
      selectedFileModel,
    };
  },
});
