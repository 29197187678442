




































import { defineComponent } from '@vue/composition-api';

import injectStrict from '@/utils/injectStrict';
import { StoreKey } from '@/store';

import DataSourcePicker from '@/pages/StressMapPage/components/DataSourcePicker';
import OpacityPicker from '@/pages/StressMapPage/components/OpacityPicker';
import RangePicker from '@/pages/StressMapPage/components/RangePicker';
import BaseSpinner from '@/components/BaseSpinner';
import HumbleLink from '@/pages/StressMapPage/components/HumbleLink';
import MapLayerPicker from '@/pages/StressMapPage/components/MapLayerPicker';

import useTheInfoArea from '@/pages/StressMapPage/components/TheInfoArea/useTheInfoArea';

import useSettingsWidget from './useSettingsWidget';

export default defineComponent({
  name: 'SettingsWidget',
  components: {
    HumbleLink,
    DataSourcePicker,
    OpacityPicker,
    RangePicker,
    BaseSpinner,
    MapLayerPicker,
  },
  setup() {
    const store = injectStrict(StoreKey);

    const { model } = useSettingsWidget();
    const { model: theInfoAreaModel } = useTheInfoArea();
    return {
      model,
      theInfoAreaModel,
      isEmbedded: store.init.isEmbedded,
    };
  },
});
