import { computed, ref } from '@vue/composition-api';

import useBooleanModel from '@/utils/useBooleanModel';
import injectStrict from '@/utils/injectStrict';
import { StoreKey } from '@/store';

const { state: booleanState, toggle } = useBooleanModel(true);

const state = ref({
  isOpen: booleanState,
});

export default function useSettingsWidget() {
  const store = injectStrict(StoreKey);
  return {
    model: computed(() => ({
      isOpen: state.value.isOpen,
      isChartLoaded: Boolean(store.model.chart.piecewise.validated.filter.range),
    })),
    toggle,
  };
}
