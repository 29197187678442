







import { defineComponent } from '@vue/composition-api';
import { ExternalLinkIcon } from 'vue-feather-icons';

import LinkWithParams from '@/components/LinkWithParams';

export default defineComponent({
  name: 'HumbleLink',
  components: {
    LinkWithParams,
    ExternalLinkIcon,
  },
});
