/* eslint-disable no-underscore-dangle,no-plusplus,@typescript-eslint/ban-ts-comment,@typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types,@typescript-eslint/ban-types */
/**
 * 判断是否为对象
 * @param value
 * @returns {boolean}
 */

const isObject = (value: any): boolean => {
  const type = typeof value;
  return value !== null && (type === 'object' || type === 'function');
};

/**
 * merge
 * @param a
 * @param b
 * @returns {*}
 */
const merge = (a: any, b: any): any => {
  Object.keys(b).forEach((key) => {
    if (isObject(b[key]) && isObject(a[key])) {
      merge(a[key], b[key]);
    } else {
      // eslint-disable-next-line no-param-reassign
      a[key] = b[key];
    }
  });
  return a;
};

/**
 * bind context
 * @param func
 * @param context
 * @param args
 */
const bind = function bind(func: Function, context: any, ...args: any[]): Function {
  return function bindI(...innerArgs: any[]) {
    return func.apply(context, args.concat(Array.prototype.slice.call(innerArgs)));
  };
};

/**
 * add own item
 * @param array
 * @param item
 */
const arrayAdd = function arrayAdd(array: any[], item: any): any[] {
  let i = 0;
  let index;
  const { length } = array;
  // eslint-disable-next-line no-plusplus
  for (; i < length; i++) {
    if (array[i].seriesIndex === item.seriesIndex) {
      index = i;
      break;
    }
  }
  if (index === undefined) {
    array.push(item);
  } else {
    // eslint-disable-next-line no-param-reassign
    array[index] = item;
  }
  return array;
};

const uuid = function uuid(): string {
  function rd(a?: number | undefined) {
    return a
      ? // eslint-disable-next-line no-bitwise
        (a ^ ((Math.random() * 16) >> (a / 4))).toString(16)
      : // @ts-ignore
        ([1e7] + -[1e3] + -4e3 + -8e3 + -1e11).replace(/[018]/g, rd);
  }
  return rd();
};

/**
 * bind function array
 * @param fns
 * @param context
 */
function bindAll(fns: string[] | number[], context: any) {
  fns.forEach((fn: string | number) => {
    if (!context[fn]) {
      return;
    }
    context[fn] = context[fn].bind(context);
  });
}

/**
 * remove node
 * @param node
 */
function removeNode(node: HTMLElement) {
  return node && node.parentNode ? node.parentNode.removeChild(node) : null;
}

/**
 * mock zrender mouse event
 * @param type
 * @param event
 */
function mockEvent(type: string, event: any) {
  let { offsetX, offsetY } = event.originalEvent;

  // OffsetX and OffsetY are always zero/undefined in firefox on click, so we have to calculate it
  if (type === 'click' && !offsetX && !offsetY) {
    const target = event.originalEvent.target || event.originalEvent.srcElement;
    const rect = target.getBoundingClientRect();
    offsetX = event.originalEvent.clientX - rect.left;
    offsetY = event.originalEvent.clientY - rect.top;
  }

  const e = new MouseEvent(type, {
    // set bubbles, so zrender can receive the mock event. ref: https://dom.spec.whatwg.org/#interface-event
    // "event.bubbles": Returns true or false depending on how event was initialized.
    // True if event goes through its target’s ancestors in reverse tree order, and false otherwise
    bubbles: true,
    cancelable: true,
    button: event.originalEvent.button,
    buttons: event.originalEvent.buttons,
    clientX: event.originalEvent.clientX,
    clientY: event.originalEvent.clientY,
    // @ts-ignore
    zrX: event.originalEvent.offsetX,
    zrY: event.originalEvent.offsetY,
    movementX: event.originalEvent.movementX,
    movementY: event.originalEvent.movementY,
    relatedTarget: event.originalEvent.relatedTarget,
    screenX: event.originalEvent.screenX,
    screenY: event.originalEvent.screenY,
    view: window,
  });

  // @ts-ignore
  e.zrX = offsetX;
  // @ts-ignore
  e.zrY = offsetY;
  // @ts-ignore
  e.event = e;

  return e;
}

export { merge, isObject, bind, arrayAdd, uuid, bindAll, removeNode, mockEvent };
